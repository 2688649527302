import { Avatar, Badge, Stack, Typography } from "@mui/material";
import { Person } from "@mui/icons-material/";
import React from "react";
import { styled } from "@mui/system";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Profile = ({ name }) => {
  if (name) {
    return (
      <Stack flexDirection="row" alignItems="center">
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar alt="Satoshi Nakamoto" sx={{ bgcolor: "text.secondary" }}>
            <Person />
          </Avatar>
        </StyledBadge>
        <Typography variant="body1" ml="10px">
          Satoshi Nakamoto
        </Typography>
      </Stack>
    );
  } else {
    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Avatar alt="Satoshi Nakamoto" sx={{ bgcolor: "text.secondary" }}>
          <Person />
        </Avatar>
      </StyledBadge>
    );
  }
};

export default Profile;
